import { useUiStore } from '@/stores/ui'
import Vue from 'vue'
import { WHITELISTED_PATHS } from '@/constants'

export default function ({ route, app }) {
  // Store the initial route and query
  let currentRoute = route
  let currentQuery = route.query

  const getAppLink = (currentRoute, query) => {
    const params = {}
    Object.keys(query).forEach(key => {
      params[key] = query[key]
    })

    const queries = new URLSearchParams()
    Object.keys(params).forEach(key => {
      if (params[key]) queries.append(key, params[key])
    })

    const locale = app.i18n.locale
    const isMainPage = currentRoute.name && currentRoute.name.includes('index')
    const localePattern = /^\/[a-z]{2}\//
    const fullPath = currentRoute.path !== '/' ? currentRoute.path : ''
    const path = isMainPage
      ? 'main'
      : locale !== 'az'
      ? fullPath.replace(localePattern, '')
      : fullPath.replace(/^\//, '')
    return `umico://${path}?${queries.toString()}`
  }

  const openNativeApp = async () => {
    const uiStore = useUiStore()
    await Vue.nextTick() // Ensure the DOM and reactivity system are updated

    const originalLink = getAppLink(currentRoute, currentQuery)

    uiStore.testRedirectAppUrl = originalLink
    window.location.href = originalLink

    const timeout = setTimeout(() => {
      if (app.$device.isAndroid) {
        uiStore.popupAppMarket.link =
          'https://play.google.com/store/apps/details?id=com.competo.umicoloy'
      } else if (app.$device.isIos) {
        uiStore.popupAppMarket.link =
          'https://apps.apple.com/az/app/com.competo.umicoprod/id1458111389'
      }
      uiStore.popupAppMarket.visible = true
    }, 2000)

    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        clearTimeout(timeout)
        uiStore.popupAppMarket.visible = false
      }
    })
  }

  const pathMatchesWhitelist = (currentPath, whitelistedPaths) => {
    return whitelistedPaths.some(path => {
      const pathRegex = new RegExp(`^${path.replace(/\*/g, '.*')}$`)
      return pathRegex.test(currentPath)
    })
  }

  if (app.$device.isAndroid || app.$device.isIos) {
    Vue.prototype.$openNativeApp = openNativeApp

    if (pathMatchesWhitelist(route.path, WHITELISTED_PATHS)) {
      Vue.nextTick(() => {
        openNativeApp()
      })
    }
  }

  app.router.afterEach((to, from) => {
    currentRoute = to
    currentQuery = to.query
  })
}
