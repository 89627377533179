import { useCheckoutCredit } from '~/stores/checkout_credit'
import { useUiStore } from '~/stores/ui'
import store from '~/store/index'
import { LOG_ERROR, serialize, getCityInfo } from '~/utilities'
import { useLoyaltyStore } from '~/stores/loyalty'
import { useMarketStore } from '~/stores/market'
import crypto from 'crypto'
import { setTimeout } from 'timers'
import { useCustomer } from '@/stores/customer/index'

export default {
  setGeo(geo) {
    if (geo) {
      this.geo = geo
    }
  },
  async getCitiesByCountry(id) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const start = Date.now()
      const response = await this.$nuxt.$axios.get(
        `helpers/countries/${id}/cities`,
      )
      const currentCityId = this.geo.cityId
      const currentCity = response.data.data.find(
        city => +city.id === +currentCityId,
      )

      const city = {
        cityName: currentCity.attributes.name,
        countryId: 1,
        cityId: currentCity.id,
        selectedCoordinate: `${currentCity.attributes.lat},${currentCity.attributes.lng}`,
      }

      await this.setCitiesByCountry(response.data.data)
      this.setGeo(city)
      this.$nuxt.$auth.$storage.setUniversal('geo', city, true)

      return response
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getCitiesByCountry', e, { id })
      throw e
    }
  },
  setUserCreditBalance(data) {
    const end_date_credit = data?.сl_actl_end_date
    const is_credit = new Date(end_date_credit).getTime() - Date.now()
    this.creditUserBalance = is_credit >= 0 ? data : {}
  },
  uploadSubscriptionMarketingMailing() {
    try {
      if (this.subscriptionMarketingMailing) {
        if (process.server) {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
            this.$nuxt.$config.CUSTOMER_BASE_URL
        } else {
          this.$nuxt.$axios.defaults.baseURL =
            this.$nuxt.$config.CUSTOMER_BASE_URL
        }
        const data = {
          client_subscription_attributes: {
            accepts_mp_marketing_mailing: true,
          },
        }
        this.$nuxt.$axios.put('/v2/clients/account', data)
      }
    } catch (e) {
      LOG_ERROR(
        this?.$rollbar,
        this?.$config,
        'uploadSubscriptionMarketingMailing',
        e,
      )
    }
  },
  async signUp(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const response = await this.$nuxt.$axios.post(
        `/v2/clients/account/sign-up`,
        data,
      )
      return response.data
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'signUp', e, data)
      throw e
    }
  },
  async signInPre(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return this.$nuxt.$axios.get(`/v2/clients/account/sign-in`, {
        params: {
          phone: data.phone,
        },
      })
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'signInPre', e, data)
      throw e
    }
  },
  signIn(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    return new Promise((resolve, reject) => {
      this.$nuxt.$axios
        .post(`/v2/clients/account/sign-in`, data)
        .then(response => {
          if (response.data.access_token && response.data.active) {
            this.$nuxt.$auth.$storage.setState(
              'access_token',
              response.data.access_token,
              false,
            )
            this.$nuxt.$auth.$storage.setCookie(
              'access_token',
              response.data.access_token,
            )
            this.$nuxt.$auth.$storage.setState(
              'refresh_token',
              response.data.refresh_token,
              false,
            )
            this.getUser().then(res => {
              const cityInfo =
                res?.data?.attributes?.addresses?.data?.[0]?.attributes?.city
                  ?.data
              const city = getCityInfo({ cityInfo, app: this.$nuxt.app })
              this.$nuxt.$auth.$storage.setCookie(
                'access_token',
                response.data.access_token,
                false,
              )
              this.$nuxt.$auth.$storage.setLocalStorage(
                'access_token',
                response.data.access_token,
                false,
              )
              this.$nuxt.$auth.$storage.setLocalStorage(
                'refresh_token',
                response.data.refresh_token,
                false,
              )
              this.$nuxt.$auth.$storage.setUniversal(
                'X-Trace-ID',
                response.data.session_state,
                false,
              )
              this.setGeo(city)
              this.$nuxt.$auth.$storage.setUniversal('geo', city, true)

              const loyaltyStore = useLoyaltyStore()
              loyaltyStore.getCard().then(res => {
                resolve(response.data)
              })
              setTimeout(() => {
                const CheckoutCredit = useCheckoutCredit()
                CheckoutCredit.CHECK_USER_CREDIT_BALANCE()
              }, 0)
            })

            if (typeof exponea !== 'undefined') {
              exponea.identify({
                customer_ext_id: response?.data?.ext_id,
              })
            }
          } else {
            resolve(response.data)
          }
        })
        .catch(e => {
          LOG_ERROR(this?.$rollbar, this?.$config, 'signIn', e, data)
          reject(e)
        })
    })
  },
  async checkOtpCodeAndConfirm(code) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const response = await this.$nuxt.$axios.post(
        `/v2/clients/account/check-otp-code-and-confirm`,
        {
          otp_code: code.otp_code,
          phone: code.phone,
        },
      )
      return response.data
    } catch (e) {
      LOG_ERROR(
        this?.$rollbar,
        this?.$config,
        'checkOtpCodeAndConfirm',
        e,
        code,
      )
      throw e
    }
  },
  async getUser() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const response = await this.$nuxt.$axios.get(`/v2/clients/account`)

      const res = response.data.data.attributes

      this.$nuxt.$auth.$storage.setState('user', res, true)
      this.$nuxt.$auth.$storage.setLocalStorage('user', res, true)
      this.setUserProfile(response.data.data)

      return response.data
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getUser', error)
      throw error
    }
  },
  async getUserByPhone({ phone }) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const data = {}
      if (phone) {
        data.phone = phone
      }
      const response = await this.$nuxt.$axios.post(
        '/helpers/clients/by-phone',
        data,
      )
      return response.data
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getUserByPhone', error, {
        phone,
      })
      throw error
    }
  },
  async getUserProfile() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const response = await this.$nuxt.$axios.get(`/v2/clients/account`)
      const userProfile = response.data.data
      this.setUserProfile(userProfile)
      return userProfile
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getUserProfile', error)
      throw error
    }
  },
  async getNewsletterSubscription() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const response = await this.$nuxt.$axios.get(
        `/v2/clients/account/exponea/consents`,
      )
      response?.data?.results?.forEach(item => {
        const translations = item.translations

        if (translations.hasOwnProperty('')) {
          translations.az = translations['']

          delete translations['']
        }
      })
      this.subscriptionNewsletter = response?.data?.results
    } catch (error) {
      LOG_ERROR(
        this?.$rollbar,
        this?.$config,
        'getNewsletterSubscription',
        error,
      )
      throw error
    }
  },
  async updateNewsletterSubsciption() {
    let data = {}
    data = {
      customer_consents: this.subscriptionNewsletter.map(subscription => ({
        category: subscription.id,
        action: subscription.user_status_value ? 'accept' : 'reject',
      })),
    }

    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.put(
        '/v2/clients/account/exponea/update_consents',
        data,
      )
    } catch (error) {
      LOG_ERROR(
        this?.$rollbar,
        this?.$config,
        'updateNewsletterSubsciption',
        error,
        {
          data,
        },
      )
      throw error
    }
  },

  async signOut(data) {
    const loyaltyStore = useLoyaltyStore()

    const marketStore = useMarketStore()
    const UiStore = useUiStore()
    UiStore.creditLineBanner = false

    loyaltyStore.clearUserData()

    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }

    try {
      const response = await this.$nuxt.$axios.delete(
        '/v2/clients/account/sign-out',
        {
          params: data,
        },
      )
      const default_city = {
        cityName: this.$nuxt.app.i18n.messages[this.$nuxt.app.i18n.locale].baku,
        countryId: 1,
        cityId: 1,
        selectedCoordinate: '40.372508,49.842474',
      }

      this.$nuxt.$auth.$storage.setUniversal('loggedIn', null, true)
      this.$nuxt.$auth.$storage.setUniversal('access_token', null, true)
      this.$nuxt.$auth.$storage.setLocalStorage('refresh_token', null, true)
      this.$nuxt.$auth.$storage.setUniversal('user', null, true)
      this.$nuxt.$auth.$storage.setUniversal('user_ext_id', null, true)
      this.$nuxt.$auth.$storage.setUniversal('X-Trace-ID', null, true)
      this.$nuxt.$auth.$storage.removeUniversal('geo', true)
      this.$nuxt.$auth.$storage.setUniversal('user_cart_id', null)
      this.$nuxt.$auth.$storage.setUniversal('cart_id', null)
      this.setUserProfile(null)

      this.setGeo(default_city)

      marketStore.viewedProducts = []
      marketStore.cart = null
      marketStore.cartCount = 0
      marketStore.favoriteUUIDS = []
      marketStore.cartUUIDS = []
      marketStore.favorites = {}
      marketStore.favoritesCount = null
      marketStore.favoriteProducts = []
      marketStore.personalRecommendationsProducts = []

      if (typeof exponea !== 'undefined') {
        exponea.anonymize()
      }

      return response
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'signOut', error, { data })
      throw error
    }
  },
  async updatePassword(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.put('/v2/clients/account/passwords', data)
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'updatePassword', error, {
        data,
      })
      throw error
    }
  },
  async updateProfile(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const response = await this.$nuxt.$axios.put('/v2/clients/account', data)
      const cityInfo =
        response?.data?.data?.attributes?.addresses?.data?.[0]?.attributes?.city
          ?.data

      const city = getCityInfo({ cityInfo, app: this.$nuxt.app })
      this.setGeo(city)
      this.$nuxt.$auth.$storage.setUniversal('geo', city, true)

      return response
    } catch (error) {
      console.error('Error while processing city info:', error)
      LOG_ERROR(this?.$rollbar, this?.$config, 'updateProfile', error, { data })
      throw error
    }
  },
  async uploadAvatar(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.put('/v2/clients/account', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'uploadAvatar', error, { data })
      throw error
    }
  },
  async getCountries() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      const start = Date.now()
      const response = await this.$nuxt.$axios.get('helpers/countries')
      this.setCountries(response.data.data)
      return response
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getCountries', error)
      throw error
    }
  },
  async sendOtpCode(phone) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      let phone_number = {}
      if (phone) {
        phone_number = {
          phone,
        }
      }
      return await this.$nuxt.$axios.post(
        `/v2/clients/account/send-otp-code`,
        phone_number,
      )
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'sendOtpCode', error, { phone })
      throw error
    }
  },
  async getOtpCode() {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.get(`/v2/clients/account/send-otp-code`)
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'getOtpCode', error)
      throw error
    }
  },
  async changePhone(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.put(
        '/v2/clients/account/change-number',
        data,
      )
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'changePhone', error, data)
      throw error
    }
  },
  async forgotPassword(phone) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.post(
        '/v2/clients/account/passwords/forgot-password',
        {
          phone,
        },
      )
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'forgotPassword', error, {
        phone,
      })
      throw error
    }
  },
  async setNewPassword(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.put(
        '/v2/clients/account/passwords/set-new-password',
        data,
      )
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'setNewPassword', error, {
        data,
      })
      throw error
    }
  },
  getPosition() {
    const options = {
      enableHighAccuracy: true,
      timeout: 15000,
      maximumAge: 0,
    }

    if (!navigator.geolocation || !navigator.geolocation.getCurrentPosition) {
      this.setUserCoordinate()
      return
    }

    const onSuccess = position => {
      this.setUserCoordinate(position)
    }

    const onError = () => {
      this.setUserCoordinate()
    }
    navigator.geolocation.getCurrentPosition(onSuccess, onError, options)
  },
  async becomePartner(data) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }
    try {
      return await this.$nuxt.$axios.post('helpers/partner-leads', data)
    } catch (error) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'becomePartner', error, data)
      throw error
    }
  },
  // mutations
  setPopupGuestCheckoutAuth(open) {
    this.openPopupGuestCheckoutAuth = open
  },
  setPopupGuestCheckoutAuthNecessary(open) {
    this.openPopupGuestCheckoutAuthNecessary = open
  },
  setUserProfile(userProfile = null) {
    this.userProfile = userProfile
  },
  setCountries(countries) {
    this.countries = countries
  },
  setCitiesByCountry(cities) {
    this.cities = cities
  },
  setUserCoordinate(position) {
    if (position && position.coords) {
      this.userCoordinate = `${position.coords.latitude},${position.coords.longitude}`
    } else {
      this.userCoordinate = ''
    }
  },
  setSubscriptionMarketingMailing(data) {
    this.subscriptionMarketingMailing = data
  },
  async sendCooperationUmico(data) {
    try {
      function utf8_to_b64(str) {
        return window.btoa(unescape(encodeURIComponent(str)))
      }
      const auth = 'competo:14BADA71352AF5740757094E1F3738E2'
      const set_auth = utf8_to_b64(auth)
      const HTTP = this.$nuxt.$axios.create({
        baseURL: 'https://esputnik.com',
        transformRequest: [
          function (data, headers) {
            headers.Authorization = `Basic ${set_auth}`
            return data
          },
        ],
      })
      return await HTTP.post('/api/v1/event', data)
    } catch (e) {
      LOG_ERROR(this?.$rollbar, this?.$config, 'sendCooperationUmico', e, data)
      return e
    }
  },
  async getOrdersDeliveryDate(order) {
    const options = {
      days: 4,
      latitude: order.shipping.latitude,
      longitude: order.shipping.longitude,
      ignore_availability: true,
      ignore_today_timeframe: true,
    }
    const order_id = order?.id
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    const urlDeliveryTimeFrames = `/orders/${order_id}/delivery-time-frames`
    const {
      data: { data = [] },
    } = await this.$nuxt.$axios
      .get(`${urlDeliveryTimeFrames}?${serialize(options)}`)
      .catch(error => {
        LOG_ERROR(this?.$rollbar, this?.$config, 'getOrdersDeliveryDate', error)
        return { data: { data: [] } }
      })
    return data
  },
  async setNewOrderDeliveryDate(data_delivery) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
    }
    const options = {
      order_ids: [data_delivery.order_ids],
      date: data_delivery.date,
      time_frame_code: data_delivery.time_frame_code,
    }
    const { data = {} } = await this.$nuxt.$axios
      .put(`orders/delivery`, options)
      .catch(error => {
        console.log('Error setNewOrderDeliveryDate', error)
        return error
      })
    return data
  },
  // async getPudoCities() {
  //   if (process.server) {
  //     this.$nuxt.$axios.defaults.baseURL =
  //       this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
  //   } else {
  //     this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
  //   }
  //   const options = {
  //     'pudo_point_type[]': [
  //       'PARTNER_LE_10',
  //       'PARTNER_LE_30',
  //       'OWN_CERTIFIED_LE_10',
  //       'OWN_CERTIFIED_LE_30',
  //       'OWN_CERTIFIED_SVC_CNTR',
  //       'PUDO_WITH_FITTING',
  //     ],
  //   }
  //   const response = await this.$nuxt.$axios.get(
  //     `/pudo-cities?${serialize(options)}`,
  //   )
  //   // const response = await this.$nuxt.$axios.get(`/pudo-cities`)
  //   await this.setCitiesByCountry(response.data.data)
  // },
  // async sendOtpCodeCreditVerification(data) {
  //   try {
  //     if (process.server) {
  //       this.$nuxt.$axios.defaults.baseURL =
  //         this.$nuxt.$config.NODE_CART_API_URL ||
  //         this.$nuxt.$config.CART_API_URL
  //     } else {
  //       this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
  //     }
  //     return await this.$nuxt.$axios.post('/verifications', data)
  //   } catch (e) {
  //     console.log('Error sendOtpCodeCreditVerification', e.data)
  //   }
  // },
  // async sendOtpCodeCreditVerificationBroker(data) {
  //   try {
  //     if (process.server) {
  //       this.$nuxt.$axios.defaults.baseURL =
  //         this.$nuxt.$config.NODE_BROKER_BASE_URL ||
  //         this.$nuxt.$config.BROKER_BASE_URL
  //     } else {
  //       this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
  //     }
  //     return await this.$nuxt.$axios.post('/api/v2/otp/send', data)
  //   } catch (e) {
  //     console.log('Error sendOtpCodeCreditVerification', e.data)
  //   }
  // },
  // async sendOtpCodeCreditConfirm(data) {
  //   if (process.server) {
  //     this.$nuxt.$axios.defaults.baseURL =
  //       this.$nuxt.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
  //   } else {
  //     this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
  //   }
  //   return await this.$nuxt.$axios.post('/verifications/confirmation', data)
  // },
  // async sendOtpCodeCreditBroker(data) {
  //   if (process.server) {
  //     this.$nuxt.$axios.defaults.baseURL =
  //       this.$nuxt.$config.NODE_BROKER_BASE_URL ||
  //       this.$nuxt.$config.BROKER_BASE_URL
  //   } else {
  //     this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.BROKER_BASE_URL
  //   }
  //   return await this.$nuxt.$axios.post('/api/v2/otp/verify', data)
  // },
  async getOrderCredit(order_id) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$config.NODE_CART_API_URL || this.$nuxt.$config.CART_API_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CART_API_URL
      }
      const { data } = await this.$nuxt.$axios.get(
        `/orders-packages/${order_id}`,
      )
      this.accountOrderCredit = data?.data
    } catch (e) {
      console.log('ERROR GET_ORDER_CREDIT', e)
      LOG_ERROR(
        this?.$rollbar,
        this.$nuxt?.$config,
        'GET_ORDERS_PACKAGES_BY_ID',
        e,
        {
          order_id,
        },
      )
    }
  },
  async getOrderPaymentMethods(orderId) {
    this.$nuxt.$axios.defaults.baseURL =
      this.$nuxt.$config.CART_API_URL.replace('/v1', '/v2')

    const response = await this.$nuxt.$axios.get(
      `/order-packages/${orderId}/payment/methods`,
    )
    return response?.data?.data?.map(payment_method => payment_method.code)
  },

  // START NEW SINGLE
  async singleIdBirID(options) {
    if (process.server) {
      this.$nuxt.$axios.defaults.baseURL =
        this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
        this.$nuxt.$config.CUSTOMER_BASE_URL
    } else {
      this.$nuxt.$axios.defaults.baseURL = this.$nuxt.$config.CUSTOMER_BASE_URL
    }

    const auth_options = {
      channel: 'web',
      redirect_url: options.redirect_url,
    }

    const response = await this.$nuxt.$axios.post(
      'v2/clients/account/singleid_auth_session',
      auth_options,
    )

    const session_id = response?.data?.session_id
    function base64URLEncode(str) {
      return str
        .toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '')
    }

    function sha256(buffer) {
      return crypto.createHash('sha256').update(buffer).digest()
    }

    const verifier = base64URLEncode(crypto.randomBytes(32))
    const challenge = base64URLEncode(sha256(verifier))
    localStorage.setItem('session_id', session_id)
    localStorage.setItem('verifier', verifier)
    localStorage.setItem('redirect_url', options?.redirect_url)

    const optionsSingleId = {
      response_type: 'code',
      client_id: response?.data?.client_id,
      state: String(response?.data?.state),
      nonce: response?.data?.nonce,
      code_challenge: challenge,
      scope: 'openid',
      code_challenge_method: 'S256',
    }
    if (options.login_hint) {
      optionsSingleId.login_hint = options.login_hint
    }
    return `${response?.data?.auth_endpoint}?${serialize(
      optionsSingleId,
    )}&redirect_uri=${options?.redirect_url}&ui_locales=${options?.ui_locales}`
  },

  async checkSingleId(route) {
    try {
      if (process.server) {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.NODE_CUSTOMER_BASE_URL ||
          this.$nuxt.$config.CUSTOMER_BASE_URL
      } else {
        this.$nuxt.$axios.defaults.baseURL =
          this.$nuxt.$config.CUSTOMER_BASE_URL
      }
      if (
        !route?.query?.state ||
        !route?.query?.code ||
        !localStorage.getItem('verifier') ||
        !localStorage.getItem('session_id') ||
        !localStorage.getItem('redirect_url')
      ) {
        return 'empty'
      }
      const options = {
        state: route?.query?.state,
        code: route?.query?.code,
        redirect_uri: localStorage.getItem('redirect_url'),
        code_verifier: localStorage.getItem('verifier'),
        session_id: localStorage.getItem('session_id'),
      }

      const response = await this.$nuxt.$axios
        .post('v2/clients/account/singleid_auth', options)
        .catch(e => {
          console.log('e', e)
          localStorage.removeItem('redirect_url')
          localStorage.removeItem('redirect_url')
          localStorage.removeItem('verifier')
          return 'error'
        })
      localStorage.removeItem('redirect_url')
      localStorage.removeItem('redirect_url')
      localStorage.removeItem('verifier')

      const auth_scenario = response?.data?.auth_scenario
      if (auth_scenario === 'sign_in' || auth_scenario == 'sign_up') {
        this.$nuxt.$auth.$storage.setState(
          'access_token',
          response.data.access_token,
          false,
        )
        this.$nuxt.$auth.$storage.setState(
          'refresh_token',
          response.data.refresh_token,
          false,
        )

        this.$nuxt.$axios.setToken(
          this.$nuxt.$auth.$storage.getState('access_token'),
          'Bearer',
        )
        this.$nuxt.$auth.$storage.setUniversal(
          'access_token',
          response.data.access_token,
          false,
        )
        this.$nuxt.$auth.$storage.setLocalStorage(
          'refresh_token',
          response.data.refresh_token,
          false,
        )
        const customerStore = useCustomer()
        const response_account = await customerStore.getUser()
        this.$nuxt.$auth.$storage.setUniversal(
          'user_ext_id',
          response_account?.data?.attributes?.ext_id,
          true,
        )

        this.$nuxt.$auth.$storage.setUniversal('loggedIn', true, true)
        this.$nuxt.$auth.$storage.setUniversal('isExistingUser', true)

        this.$nuxt.$auth.$storage.setUniversal(
          'user',
          response?.data?.attributes,
          true,
        )
        return auth_scenario
      } else {
        return 'error'
      }
    } catch (e) {
      console.log('checkSingleId', e)
      return 'error'
    }
  },
}
