export default ({ app }) => {
  let startApp = false
  app.router.beforeEach((to, from, next) => {
    // Path what ignore writing to local storage for went after login
    const ignorePath = [
      'auth-authentication___az',
      'auth-authentication___ru',
      'checkout-authentication___az',
      'checkout-authentication___ru',
      'account-id-card___ru',
      'account-id-card___az',
      'sso_auth___ru',
      'sso_auth___az',
    ]
    if (!ignorePath.includes(to.name)) {
      app.$auth.$storage.setLocalStorage('nextPage', to.name)
      app.$auth.$storage.setLocalStorage(
        'nextPageParams',
        JSON.parse(JSON.stringify(to.params)),
      )
      app.$auth.$storage.setLocalStorage(
        'nextPageQuery',
        JSON.parse(JSON.stringify(to.query)),
      )
    }
    next()
  })
  app.router.afterEach((to, from) => {
    if (!startApp && process.client) {
      if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
          event: 'user_locale',
          user_lang: app.i18n.locale,
        })
      }
      startApp = true
    }
  })
}
